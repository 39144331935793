// SPDX-FileCopyrightText: © 2017 EteSync Authors
// SPDX-License-Identifier: AGPL-3.0-only

export const appName = "EteSync";
export const homePage = "https://www.etesync.com/";
export const faq = homePage + "faq/";
export const sourceCode = "https://github.com/etesync/etesync-web";
export const reportIssue = sourceCode + "/issues";
export const newWebClient = "https://pim.etesync.com/";

export const forgotPassword = "https://www.etesync.com/accounts/password/reset/";
export const signUp = "https://www.etesync.com/accounts/signup/";

export const serviceApiBase = process.env.REACT_APP_DEFAULT_API_PATH || "https://api.etesync.com/";
